import { Box } from 'grommet'
import { ReactComponent as Logo } from '../../content/logo.svg'
import { ThetaboxDiscord, TwitterThetaBox } from '../common/SocialsButton'
import { HomeDiagram } from './HomeDiagram'

export default function Home() {
	return (
		<Box fill>
			<Box flex overflow="auto">
				<Box width="medium" alignSelf="center">
					<Logo />
					<HomeDiagram />
				</Box>
			</Box>
			<Box flex={false}  pad="small">
				<Box direction="row" alignSelf="center" >
					<ThetaboxDiscord />
					<TwitterThetaBox/>
				</Box>
			</Box>
		</Box>
	)
}
